import { Controller } from "stimulus";
import bootstrap from "bootstrap/dist/js/bootstrap";

export default class extends Controller {
  static targets = [
    "modal",
    "orderQty",
    "btnCart",
    "modifierCheckbox",
    "itemPrice",
    "modifierPrice",
    "editModal",
    "editModalContent",
  ];

  connect() {
    if (this.hasModalTarget) {
      this.modalTarget.addEventListener(
        "shown.bs.modal",
        this.checkboxLimit.bind(this)
      );
      this.checkboxLimit();
    }
  }

  updateCart(event) {
    const [_data, _status, xhr] = event.detail;
    document.getElementById("cart").innerHTML = xhr.response;
    if (event.target.tagName === "FORM") {
      this.resetForm(event.target);
    }
    this.updateCartQty();
    this.showLatestCartItem();
  }

  showLatestCartItem() {
    const lastCartItem = document
      .querySelector(".cart--inner")
      .querySelector(".cart-item:last-child");

    if (lastCartItem) {
      const itemName = lastCartItem
        .querySelector(".cart-title")
        .textContent.trim();
      const itemPrice = lastCartItem
        .querySelector(".cart-price")
        .textContent.trim();
      const itemQty = lastCartItem
        .querySelector(".cart-qty")
        .textContent.trim();

      this.showAlert(itemName, itemPrice, itemQty);
    }
  }

  showAlert(name, price, amount) {
    const alertElement = document.createElement("div");
    alertElement.className = "alert custom-alert fade show";
    alertElement.setAttribute("role", "alert");
    alertElement.style.zIndex = "1080 !important";

    alertElement.innerHTML = `
        <div class="alert-message">
            Added ${amount} x <strong>${name}</strong> for ${price} to your cart.
        </div>
        <button type="button" class="btn-close" aria-label="Close">
            <i class="bi bi-x-lg" style="color: rgba(255, 255, 255, 0.9);"></i>
        </button>
    `;
    const alertContainer = document.querySelector(".alert-container");

    alertContainer.insertAdjacentElement("afterbegin", alertElement);

    const closeButton = alertElement.querySelector(".btn-close");
    closeButton.addEventListener("click", () => {
      alertElement.remove();
    });
    const alertMessage = alertElement.querySelector(".alert-message");
    alertMessage.addEventListener("click", () => {
      this.openOffcanvas();
      alertElement.remove();
    });

    setTimeout(() => {
      alertElement.remove();
    }, 3000);
  }

  editModal(event) {
    const [_data, _status, xhr] = event.detail;
    document.getElementById("editModalContent").innerHTML = xhr.response;
    const offcanvasElement = document.getElementById("offcanvasRight");
    if (offcanvasElement) {
      const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
      if (offcanvas) {
        offcanvas.hide();
      }
    }

    const modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("editModal")
    );
    modal.show();
  }

  updateMealPrice(event) {
    let modifierPriceElement = event.target;
    let modifierPrice = event.target.dataset.modifierPrice;
    if (modifierPriceElement && modifierPrice) {
      modifierPrice = parseFloat(modifierPrice.replace("$", ""));
      let itemPriceTag = this.itemPriceTarget;
      let itemPrice = parseFloat(itemPriceTag.innerHTML.replace("$", ""));

      let total = modifierPriceElement.checked
        ? itemPrice + modifierPrice
        : itemPrice - modifierPrice;
      itemPriceTag.innerHTML = "$" + total.toFixed(2);
    }

    this.checkboxLimit();
  }

  decreaseValue() {
    const numberInput = this.orderQtyTarget;
    let value = parseInt(numberInput.value, 10);
    if (isNaN(value)) value = 0;
    numberInput.value = value > 1 ? value - 1 : 1;
    this.updateItemPrice();
  }

  increaseValue() {
    const numberInput = this.orderQtyTarget;
    let value = parseInt(numberInput.value, 10);
    if (isNaN(value)) value = 0;
    numberInput.value = value + 1;
    this.updateItemPrice();
  }

  updateItemPrice() {
    this.itemPriceTarget.innerHTML =
      "$" +
      (this.orderQtyTarget.value * this.itemPriceTarget.dataset.price).toFixed(
        2
      );
  }

  openOffcanvas() {
    const offcanvasElement = document.getElementById("offcanvasRight");

    if (!offcanvasElement) {
      return;
    }

    let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);

    if (!offcanvasInstance) {
      offcanvasInstance = new bootstrap.Offcanvas(offcanvasElement);
    }

    offcanvasInstance.show();
  }

  resetForm(formElement) {
    formElement.reset();
    const checkboxes = formElement.querySelectorAll("input[type='checkbox']");
    checkboxes.forEach((checkbox) => (checkbox.disabled = false));
    formElement.querySelector("input[type='submit']").classList.add("disabled");
    const price = formElement.querySelector(".item-price");
    if (price) {
      price.innerHTML = price.dataset.price;
    }
  }

  updateCartQty() {
    const cartCount = document.getElementsByClassName("badge").length;
    document.getElementById("cartQty").innerHTML = cartCount;
  }

  checkboxLimit() {
    const checkboxes = this.modifierCheckboxTargets;
    if (
      checkboxes.length === 0 ||
      this.modifierCheckboxTarget.dataset.min == 0
    ) {
      this.updateBtnState(true);
      return;
    }

    let allRequirementsMet = true;
    let groups = new Set(checkboxes.map((checkbox) => checkbox.dataset.group));

    groups.forEach((group) => {
      let groupCheckboxes = checkboxes.filter(
        (checkbox) => checkbox.dataset.group === group
      );
      let max = parseInt(groupCheckboxes[0].dataset.max);
      let min = parseInt(groupCheckboxes[0].dataset.min);
      let checkedCount = groupCheckboxes.filter((cb) => cb.checked).length;
      let unchecked = groupCheckboxes.filter((cb) => !cb.checked);
      // Disable unchecked boxes if max required amount is reached
      let disable = checkedCount >= max;
      unchecked.forEach((cb) => {
        cb.disabled = disable;
      });

      if (checkedCount < min && min > 0) {
        allRequirementsMet = false;
      }
    });
    this.updateBtnState(allRequirementsMet);
  }

  updateBtnState(requirements_met) {
    if (!this.hasBtnCartTarget) {
      return;
    }
    if (requirements_met) {
      this.btnCartTarget.classList.remove("disabled");
    } else {
      this.btnCartTarget.classList.add("disabled");
    }
  }
}
